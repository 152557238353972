import React from 'react'

export default function Page404({
    message
}) {
    
    return (
        <section id="about" className="about">
            <div className="container" data-aos="fade-up" style={{marginTop:'8%'}}>

                <div className="section-title">
                    <h1 className="title404">Oops !</h1> <br/>
                    <h3>We can't seem to fin the page you're looking for.</h3>
                    <div className="row content">
                        <div className="col-lg-6 pt-4 pt-lg-0" style={{position:'absolute',left:"24%"}}>
                            <a href="/" className="btn-learn-more">Home</a>
                        </div>
                    </div>
                </div>
                
            </div>
        </section>
    )
}
