import React from 'react'
import * as Images from "../assets/images"
import pages from "../assets/content"
import { CardItem, Carousel } from "../components"
import {useTranslation} from "react-i18next"

export default function Home() {
    const {t} = useTranslation()
    return (
        <section id="hero" className="d-flex align-items-center" >
            <div className="container position-relative" data-aos="fade-up" data-aos-delay="100" style={{paddingTop:'0px'}}>
            <section id="about" className="about">
                    <div className="container" data-aos="fade-up">

                        <div className="section-title">
                            <h1>Adenon</h1>
                            <h2>{t('Home.title')}</h2>
                        </div>
                        <div className="row content">
                            <div className="col-lg-12 pt-4 pt-lg-0">
                                <p style={{fontWeight:'700',fontSize:'22px'}}>
                                    {t('Home.brief')}
                                </p>
                                <a href="?adenon" className="btn-learn-more" style={{position:'relative',left:'45%'}}>{t('general.readmore')}</a>
                            </div>
                        </div>

                    </div>
                </section>

                <Carousel
                    slides={pages}
                    images={Images}
                    style={{paddingTop:'0px'}}
                />
                <div className="section-title">
                    <h1 style={{marginTop:'50px'}}>{t('general.contentTitle')}</h1>
                </div> 
                <div className="row icon-boxes">
                    {pages.map((page) => {
                        return (
                        <CardItem
                            key={page.ref}
                            page={page}
                        />
                        )
                    }
                    )}
                </div>
            </div>
        </section>
    )
}
