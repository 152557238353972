import React from 'react'
import {useTranslation} from "react-i18next"

export default function About() {
    const {t} = useTranslation()
    return (
        <section id="about" className="about" style={{position:'relative',top:'50px',marginBottom:'100px'}}>
            <div className="container" data-aos="fade-up">

                <div className="section-title">
                    <h1>{t(`about.title`)}</h1>
                </div>
                <div className="row content">
                    <div className="col-lg-8" style={{position:'relative',left:'15%'}}>
                        <p style={{fontSize:'20px',fontWeight:'600'}}>
                            {t('about.text')}
                        </p>
                    </div>
                </div>
            </div>
        </section>
    )
}
