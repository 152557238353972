import React from 'react'
import Home from "./Home"
import Page404 from "./Page404"
import PageDetail from "./Page"
import Contact from "./Contact"
import About from "./About"
import Presentation from "./Presentation"
export default function PageFactory(props) {
    const query = props.location.search.split("&")
    const route = query[0].slice(1) // to ignore the ? mark in the url
    switch (route) {
        case "detail": // render detail Page
            // get the refPage
            const ref = query.length === 2 && query[1].startsWith("ref") ? query[1].split("=")[1] : ""
            //TODO handle incorrect ref
            return ref ? <PageDetail refPage={ref}/> : <Page404/>
        case "contact":     return <Contact/>
        case "about":       return <About/>
        case "adenon":      return <Presentation/>
        case "":            return <Home/>
        default:            return <Page404/>
    }
}
