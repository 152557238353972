import React from "react"
import { Link } from "react-router-dom"
import {useTranslation} from "react-i18next"
import * as Images from "../assets/images"
const CardItem = ({ page }) => {
  const {t} = useTranslation()
  const ref = page.ref
  const description = t(`pages.${ref}.brief`)
  const title = t(`pages.${page.ref}.title`)

  const formatBrief = (brief)=>{
    const tokens = brief.split('$RET')
    return (
        <p>
            {tokens.map((tok,index)=>(
                <span key={index}>
                    {tok}
                    <br/>
                </span>
            ))}
        </p>
    )
  }
  return (
      <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-5" data-aos="zoom-in" data-aos-delay="200">
        <div className="icon-box">
          <div className="icon">
            <img
              src={Images[page.mainImage]}
              width={50}
              height={50}
              alt={page.mainImage}
              />
          </div>
          {/* the link point in the same page*/}
          <h4 className="title"><Link to={`?detail&ref=${ref}`}>{title}</Link></h4>
          <p className="description" style={{fontWeight:'600'}}>{formatBrief(description)}</p>
        </div>
      </div>
  )
}
export default CardItem;