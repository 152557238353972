
import React from 'react'

export default function Footer() {
    return (     
    <footer id="footer">
        <div className="footer-top">
        <div className="container">
            <div className="row">

            <div className="col-lg-3 col-md-6 footer-contact">
                <h3>Adenon</h3>
                <p>
                    135 Avenue de Rangeuil <br/>
                    31400 , Toulouse<br/>
                    France <br/><br/>
                    <strong>Phone:</strong> +33 (0)5 61 55 97 74<br/>
                    <strong>Email:</strong> adenon@insa-toulouse.fr<br/>
                </p>
            </div>

            <div className="col-lg-2 col-md-6 footer-links">
                <h4>Useful Links</h4>
                <ul>
                    <li><i className="bx bx-chevron-right"></i> <a href="/">Home</a></li>
                    <li><i className="bx bx-chevron-right"></i> <a href="?about">About us</a></li>
                </ul>
            </div>
        </div>
      </div>
    </div>

    <div className="container d-md-flex py-4">

      <div className="me-md-auto text-center text-md-start">
        <div className="copyright">
          &copy; Copyright <strong><span>Adenon</span></strong>. All Rights Reserved
        </div>
      </div>
    </div>
  </footer>
    )
}
