import './App.css';
import {Suspense} from "react"
import {Switch,Route} from "react-router-dom"
import Navigation from "./components/Navigation/Navigation"
import PageFactory from "./views/PageFactory"
import Footer from "./components/Footer"
import "./i18n"

function App() {
  console.log("[App] rendering ...")
  const navItems =[
    {"title":"Home","to":"/"},
    {"title":"Contact","to":"?contact"},
    {"title":"About","to":"?about"},
    
  ]
  return (
    <Suspense fallback={<div>...is loading</div>}>
      <header id="header" className="fixed-top">
        <div className="container d-flex align-items-center justify-content-between"> 
          <h1 className="logo"><a href="/">Adenon</a></h1>
          <Navigation
            navigations={navItems}
            />
        </div>
      </header>
      <main>
        <Switch>
          <Route path="/" component={PageFactory}/>
        </Switch>
      </main>
      <Footer />
    </Suspense>
  );
}

export default App;
