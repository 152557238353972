import React from 'react'
import {Container, Row,Col} from "react-bootstrap"
import {useTranslation} from "react-i18next"
import {useWindowDimensions} from "../Hooks"
import * as Images from '../assets/images'

import Page404 from "./Page404"

import {hasPage} from "../assets/content"

export default function Page(props) {

    
    console.log("[Page] rendering ...")
    const pageRef = props.refPage
    
    const {  height } = useWindowDimensions()
    const {t} = useTranslation()
    if(!hasPage(pageRef)){
        return <Page404/>
    }
    const mainImage = t(`pages.${pageRef}.mainImage`)
    const description = t(`pages.${pageRef}.main`)
    const title = t(`pages.${pageRef}.title`)
    console.log("title ",title)
    return (
        <section id="hero" className="d-flex align-items-center" >
            <div className="container position-relative" data-aos="fade-up" data-aos-delay="100" style={{paddingTop:'40px'}}>
                <Container>
                    <Row>
                        <Col sm={6} style={{height:height*0.5}}> 
                            <div style={{width:'100%',height:'100%'}}>
                                <img
                                    src={Images[mainImage]}
                                    alt={'alt'}
                                    style={{
                                        width:'100%',
                                        height:'100%',
                                        borderRadius:'8px',
                                        objectFit:'fill'
                                    }}
                                    width={500}
                                />
                            </div> 
                        </Col>
                        <Col sm={6} > 
                            <h2>{title}</h2> <br/>
                            <p style={{fontSize:'20px',fontWeight:'600'}}>{description}</p>
                        </Col>
                    </Row>
                </Container>
            </div>
        </section>
    )
}




