import { useState, useEffect } from "react";

export const Constantes ={
  DEKSTOP_DEFAULT:'DEKSTOP_DEFAULT',
  DEKSTOP_WIDE:'DEKSTOP_WIDE',
  MOBILE:'MOBILE',
  IPAD:'IPAD'
}

export const useWindowDimensions = () => {
  const [dimension, setDimension] = useState({
    height: 0,
    width: 0,
    type:''
  });

  const handleResize = () => {
    let type = Constantes.DEKSTOP_DEFAULT
    if(window.innerHeight > 700 && window.innerWidth > 1400){
      type = Constantes.DEKSTOP_WIDE
    } 
    else if (window.innerHeight < 700 && window.innerWidth < 400){
      type = Constantes.MOBILE
    }
    setDimension({
      height: window.innerHeight,
      width: window.innerWidth,
      type
    });
  };
  /* run only once, after the first render */
  useEffect(() => {
    window.addEventListener("resize", handleResize); /* Ajout d'un gestionnaire d'evenement à chaque redimensionement de la fenetre */
    handleResize();
    /* Clean up function */
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return dimension;
};