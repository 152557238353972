import {useState} from "react"
import { useWindowDimensions } from "../../Hooks"
import { Carousel } from "react-bootstrap"
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import {useTranslation} from "react-i18next"
import {Container, Row,Col} from "react-bootstrap"
import {Constantes} from "../../Hooks/useWindowsDimensions"
import "./Carousel.css"


const CustomCarousel = ({
    slides,
    images
}) => {
    
    const { width, height,type } = useWindowDimensions()
    const {t} = useTranslation()
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };
    let carouselStyle = {}
    let carouselHigh = 0 
    let top = '25px'
    let imageHeight = 0
    let marginTop = 0
  
    switch (type) {
        case Constantes.MOBILE: // pas besoin de carousel pour les mobiles
            return null;
        case Constantes.DEKSTOP_DEFAULT:
            carouselHigh = height * 0.8
            imageHeight = height * 0.6
            break;
        case Constantes.DEKSTOP_WIDE:
            carouselHigh = height * 0.85
            imageHeight = height * 0.7
            break;
        default:
            break;
    }
         
    carouselStyle = {
        marginTop:  marginTop,
        height: carouselHigh,
        top:top,
        paddingTop:'15px',
        paddingBottom:'0px'
    }

    const formatBrief = (brief)=>{
        const tokens = brief.split('$RET')
        return (
            <p>
                {tokens.map((tok,index)=>(
                    <span key={index}>
                        {tok}
                        <br/>
                    </span>
                ))}
            </p>
        )
    }
    return (
        <Carousel
            activeIndex={index}
            onSelect={handleSelect}
            nextIcon={
                <ArrowForwardIosIcon
                    style={{
                        fontSize: 50,
                        color:'black'
                    }}
                />
            }
            nextLabel=""
            prevLabel=""
            prevIcon={
                <ArrowBackIosIcon
                    style={{
                        fontSize: 50,
                        color: 'black',
                    }}
                />
            }
            style={carouselStyle}
        >
        {slides.map((slide, index) => {
            // select randomly one images in all available images for that slide
            
            const mainImage = slide.images[Math.floor(Math.random()*slide.images.length)]
            const alt = t(`pages.${slide.ref}.images.${mainImage}.alt`)
            const title = t(`pages.${slide.ref}.title`)
            const description= t(`pages.${slide.ref}.brief`)
            return (
                <Carousel.Item key={index} style={{position:'relative',paddingTop:'0px',height:'100%' , }}>
                    <Container  fluid>
                        <Row className="justify-content-md-center" style={{ position: 'relative',height:carouselHigh * 0.1,width: width, left:'8%'}}>
                            <Col style={{padding:'12px'}}>
                                <h3 style={{fontWeight:'bold',fontSize:'34px'}}>{title}</h3>
                            </Col>
                        </Row>
                        <Row style={{height:carouselHigh * 0.8}}>
                            <Col sm={8}>
                                <div style={{width:'100%',height:'100%'}}>
                                    <img
                                        src={images[mainImage]}
                                        alt={alt}
                                        style={{
                                            width:`${slide.sizeImage.width}%`,
                                            height:`${slide.sizeImage.height}%`,
                                            borderRadius:'8px',
                                            objectFit:'fill',
                                        }}
                                        height={imageHeight}
                                        width={500}
                                    />
                                </div> 
                            </Col>
                            <Col sm={4}>
                                <div style={{top:'10%', position:'relative',fontSize:'22px',fontWeight:'800'}}>
                                    {formatBrief(description)}
                                </div> 
                                <div className="text-center" style={{position:'absolute',bottom:'0px'}}>
                                    <a href={`?detail&ref=${slide.ref}`} className="btn-get-started scrollto">{t(`general.readmore`)}</a>
                                </div>
                            </Col>
    
                        </Row>
                    </Container>
                </Carousel.Item>
            )
        })}
        
        
    </Carousel>
    )
}

export default  CustomCarousel


