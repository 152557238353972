import React from 'react'
import {useTranslation} from "react-i18next"

export default function Presentation() {
    const {t} = useTranslation()
    return (
        <section id="about" className="about" style={{position:'relative',top:'50px',marginBottom:'100px'}}>
            <div className="container" data-aos="fade-up">
                <div className="section-title">
                    <h2>{t(`Home.title`)}</h2>
                    <h5>{t('Home.brief')}</h5>   
                </div>

                <div className="row content">
                    <div className="col-lg-8" style={{position:'relative',left:'15%'}}>
                        <p style={{fontWeight:'600',fontSize:'20px'}}>
                            {t('Home.description')}
                        </p>
                    </div>
                </div>
            </div>
        </section>
    )
}
