const pages = [
    {
        ref: "Adenon-biological",
        icon:"",
        mainImage:"population",
        sizeImage:{
            width:100,
            height:93
        },
        images: [
            "population",
            // add other image related to this page here
        ],
    },
    {
        ref: "Adenon-reactors",
        icon:"",
        mainImage:"flowChart",
        sizeImage:{
            width:80,
            height:70
        },
        images: [
            "flowChart",
            // add other image related to this page here
        ],
    },
    {
        ref: "Adenon-cfd-simulation",
        icon:"",
        mainImage:'cfdToCma',
        sizeImage:{
            width:100,
            height:93
        },
        images: [
            "cfdToCma",
            "cfdToCma_Maxime"
            // add other image related to this page here
        ],
    },
    {
        ref: "Adenon-gui",
        icon:"",
        mainImage:'guiExport',
        sizeImage:{
            width:100,
            height:93
        },
        images: [
            "guiExport",
            // add other image related to this page here
        ],
    },
    {
        ref: "Adenon-advanced-studies",
        icon:"",
        mainImage:'advancedStudies',
        sizeImage:{
            width:73,
            height:65,
            left:10
        },
        images: [
            "advancedStudies",
            // add other image related to this page here
        ],
    },
    {
        ref:"Adenon-computing-bioreaction-rates",
        icon:"",
        mainImage:"biological_transformation",
        images:[
            "biological_transformation"
        ],
        sizeImage:{
            width:100,
            height:93
        },
    }
]
export function hasPage(refPage){
    return pages.some((page)=>(page.ref===refPage))
}
export default pages