import React from 'react'

export default function Contact() {
    return (
        <section id="contact" className="contact">
            <div className="container" data-aos="fade-up" style={{marginTop:'15px'}}>
  
          <div className="section-title">
            <h2>Contact</h2>
            <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p>
          </div>
  
         
          <div className="row mt-5">
  
            <div className="col-lg-4">
              <div className="info">
                <div className="address">
                  <i className="bi bi-geo-alt"></i>
                  <h4>Location:</h4>
                  <p>135 Avenue de Rangeuil</p>
                </div>
  
                <div className="email">
                  <i className="bi bi-envelope"></i>
                  <h4>Email:</h4>
                  <p>adenon@insa-toulouse.fr</p>
                </div>
  
                <div className="phone">
                  <i className="bi bi-phone"></i>
                  <h4>Call:</h4>
                  <p>+33 00 00 00 00 </p>
                </div>
  
              </div>
  
            </div>
  
            
          </div>
  
        </div>
      </section>
           
        
    )
}
