import React from "react"
import { Link } from "react-router-dom"
import {useTranslation} from "react-i18next"
import {frenchFlag,englishFlag} from "../../assets/images"
const lngs = {
  en: { nativeName: 'English' , flag:englishFlag},
  fr: { nativeName: 'Français', flag: frenchFlag}
};

const Navigation = (props) => {
    const navigations = props.navigations
    const {t,i18n}  = useTranslation()
    const activelangue = lngs[i18n.language] ? lngs[i18n.language].flag: lngs['en'].flag
    const alt = lngs[i18n.language] ? lngs[i18n.language].nativeName: lngs['en'].nativeName
    const getNavItems = () => {
        return (
            <ul>
                {
                    navigations.map((navigation, index) => <li key={index}><Link className="nav-link scroollto" to={navigation.to}>{t(`navigation.${navigation.title}`)}</Link></li>)
                }
                <li className="dropdown">
                    <a href="/">
                        <img
                            src={activelangue}
                            width={30}
                            height={30}
                            alt={alt}
                        />
                        <i className="bi bi-chevron-down"></i>
                    </a>
                    <ul>
                        {Object.keys(lngs).map((lng) => (
                            <li key={lng} style={{ fontWeight: i18n.language === lng ? 'bold' : 'normal' }}  onClick={() => i18n.changeLanguage(lng)}>
                                <span>{t(`general.languages.${lng}`)}</span>
                            </li>
                         ))}
                    </ul>
                </li>
            </ul>
        )
    }
    return (
        <nav id="navbar" className="navbar">
            {getNavItems()}
            <i className="bi bi-list mobile-nav-toggle"></i>
        </nav>
    )
}

export default Navigation